import React from "react"
import { Link } from "gatsby"
import Layout from "../content/Layout"
import { StaticImage } from "gatsby-plugin-image"

import { useForm } from "@formspree/react"

import { CheckCircleIcon, XIcon } from "@heroicons/react/solid"

import SEO from "../content/Seo"

const Contact = () => {
  const [state, handleSubmit] = useForm("xnqwrpqp")

  if (state.succeeded) {
    return (
      <Layout>
        <SEO
          title="Contact Us"
          slug="/contact"
          image="../assets/Header Image Placeholder.jpg"
        />
        <main className="pt-36 pb-12">
          <div className="bg-white">
            <h2 className="px-4 font-heading text-3xl mx-auto text-ec-earth text-center pt-12">
              Contact Elemental Connection
            </h2>
            <div className="max-w-screen-2xl px-4 grid grid-cols-1 md:grid-cols-2 gap-20 mx-auto py-12">
              <div className="flex flex-col ">
                <p className=" text-center md:text-left font-body text-xl text-ec-sea leading-8 py-6">
                  Our beautiful shop in Ormiston, Queensland offers sanctuary
                  for our customers where you can get hands on with the products
                  and gain knowledge and guidance from our wonderful staff.
                </p>
                <p className="text-center md:text-left font-body text-xl text-ec-sea leading-8 py-6">
                  Additional services provided in store are:
                </p>
                <ul className="text-center md:text-left font-body text-xl text-ec-sea leading-8 pl-4">
                  <li className="flex flex-row items-center gap-4 justify-center md:justify-start pb-4">
                    <svg
                      className="h-6 w-6"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="13"
                        cy="13"
                        r="12"
                        stroke="#0D3552"
                        stroke-width="2"
                      />
                    </svg>
                    Healings
                  </li>
                  <li className="flex flex-row items-center gap-4 justify-center md:justify-start pb-4">
                    <svg
                      className="h-6 w-6"
                      width="32"
                      height="27"
                      viewBox="0 0 32 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.9282 14L16 2L9.07182 14M22.9282 14L29.8564 26H2.14362L9.07182 14M22.9282 14H9.07182"
                        stroke="#0D3552"
                        stroke-width="2"
                      />
                    </svg>
                    Readings
                  </li>
                </ul>
                <p className="text-center md:text-left font-body text-xl text-ec-sea leading-8 py-6">
                  To learn more about our talented practitioners and stay up to
                  date with the latest workshops and events, follow our socials
                  and sign up to the Elemental Community below.
                </p>
              </div>
              <div>
                <div className="text-xl font-body text-ec-sea leading-8 py-6">
                  <p>Get in touch:</p>
                  <p>Shop 1a / 1-7 Finucane Rd, Capalaba QLD 4157</p>
                  <p>
                    <strong>P</strong> 0424 979 703
                  </p>
                  <p>
                    <strong>E</strong> admin@elementalconnection.com.au
                  </p>
                  <p>Or fill in the form below:</p>
                </div>
                <form
                  onSubmit={handleSubmit}
                  method="POST"
                  className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
                >
                  <div>
                    <div className="mt-1">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autocomplete="name"
                        placeholder="Name"
                        className="py-3 px-4 block w-full text-gray-900 focus:ring-ec-earth focus:border-ec-earth border-2 border-ec-sea rounded-full"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autocomplete="email"
                        placeholder="Email or Phone"
                        className="py-3 px-4 block w-full text-gray-900 focus:ring-ec-earth focus:border-ec-earth border-2 border-ec-sea rounded-full"
                      />
                    </div>
                  </div>
                  <div className="sm:col-span-2">
                    <div class="flex justify-between">
                      <span id="message-max" className="text-sm text-gray-500">
                        Max. 500 characters
                      </span>
                    </div>
                    <div className="mt-1">
                      <textarea
                        id="message"
                        name="message"
                        rows="4"
                        placeholder="What's on your mind?"
                        class="py-3 px-4 block w-full text-gray-900 focus:ring-ec-earth focus:border-ec-earth border-2 border-ec-sea rounded-lg"
                        aria-describedby="message-max"
                      ></textarea>
                    </div>
                  </div>
                  <div className="sm:col-span-2 sm:flex sm:justify-end">
                    {state.submitting ? (
                      <div>
                        {" "}
                        <button
                          type="submit"
                          className="mt-2 w-full border-2 border-ec-earth transition ease-in-out font-heading inline-flex items-center justify-center px-6 py-3 rounded-full text-xl text-white bg-ec-earth hover:bg-transparent hover:text-ec-earth focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ec-earth sm:w-auto"
                        >
                          <svg
                            className="fill-white animate-spin mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" />
                          </svg>{" "}
                          Submitting...
                        </button>
                      </div>
                    ) : (
                      <div>
                        {" "}
                        <button
                          type="submit"
                          className="mt-2 w-full border-2 border-ec-earth transition ease-in-out font-heading inline-flex items-center justify-center px-6 py-3 rounded-full text-xl text-white bg-ec-earth hover:bg-transparent hover:text-ec-earth focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ec-earth sm:w-auto"
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </form>
                <div className="flex justify-end">
                  <div className=" bg-ec-sea p-4 mt-4 w-full sm:w-80 rounded-full text-right">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="h-5 w-5 text-white"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-sm font-medium text-white">
                          Your submission was successful!
                        </p>
                      </div>
                      <div className="ml-auto pl-3">
                        <div className="-mx-1.5 -my-1.5">
                          <button
                            onClick={() => window.location.reload(false)}
                            type="button"
                            className="inline-flex p-1.5 text-white rounded-full hover:bg-ec-earth/20 focus:outline-none"
                          >
                            <span className="sr-only">Dismiss</span>
                            <XIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )
  }
  return (
    <Layout>
      <SEO
        title="Contact Us"
        slug="/contact"
        image="../assets/Header Image Placeholder.jpg"
      />
      <main className="pt-36 pb-12">
        <div className="bg-white">
          <h2 className="px-4 font-heading text-3xl mx-auto text-ec-earth text-center pt-12">
            Contact Elemental Connection
          </h2>
          <div className="max-w-screen-2xl px-4 grid grid-cols-1 md:grid-cols-2 gap-20 mx-auto py-12">
            <div className="flex flex-col ">
              <p className=" text-center md:text-left font-body text-xl text-ec-sea leading-8 py-6">
                Our beautiful shop in Ormiston, Queensland offers sanctuary for
                our customers where you can get hands on with the products and
                gain knowledge and guidance from our wonderful staff.
              </p>
              <p className="text-center md:text-left font-body text-xl text-ec-sea leading-8 py-6">
                Additional services provided in store are:
              </p>
              <ul className="text-center md:text-left font-body text-xl text-ec-sea leading-8 pl-4">
                <li className="flex flex-row items-center gap-4 justify-center md:justify-start pb-4">
                  <svg
                    className="h-6 w-6"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="13"
                      cy="13"
                      r="12"
                      stroke="#0D3552"
                      stroke-width="2"
                    />
                  </svg>
                  Healings
                </li>
                <li className="flex flex-row items-center gap-4 justify-center md:justify-start pb-4">
                  <svg
                    className="h-6 w-6"
                    width="32"
                    height="27"
                    viewBox="0 0 32 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.9282 14L16 2L9.07182 14M22.9282 14L29.8564 26H2.14362L9.07182 14M22.9282 14H9.07182"
                      stroke="#0D3552"
                      stroke-width="2"
                    />
                  </svg>
                  Readings
                </li>
              </ul>
              <p className="text-center md:text-left font-body text-xl text-ec-sea leading-8 py-6">
                To learn more about our talented practitioners and stay up to
                date with the latest workshops and events, follow our socials
                and sign up to the Elemental Community below.
              </p>
            </div>
            <div>
              <div className="text-xl font-body text-ec-sea leading-8 py-6">
                <p>Get in touch:</p>
                <p>Shop 1a / 1-7 Finucane Rd, Capalaba QLD 4157</p>
                <p>
                  <strong>P</strong> 0424 979 703
                </p>
                <p>
                  <strong>E</strong> admin@elementalconnection.com.au
                </p>
                <p>Or fill in the form below:</p>
              </div>
              <form
                onSubmit={handleSubmit}
                method="POST"
                className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      autocomplete="name"
                      placeholder="Name"
                      className="py-3 px-4 block w-full text-gray-900 focus:ring-ec-earth focus:border-ec-earth border-2 border-ec-sea rounded-full"
                    />
                  </div>
                </div>
                <div>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      placeholder="Email or Phone"
                      className="py-3 px-4 block w-full text-gray-900 focus:ring-ec-earth focus:border-ec-earth border-2 border-ec-sea rounded-full"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div class="flex justify-between">
                    <span id="message-max" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      placeholder="What's on your mind?"
                      class="py-3 px-4 block w-full text-gray-900 focus:ring-ec-earth focus:border-ec-earth border-2 border-ec-sea rounded-lg"
                      aria-describedby="message-max"
                    ></textarea>
                  </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  {state.submitting ? (
                    <div>
                      {" "}
                      <button
                        type="submit"
                        className="mt-2 w-full border-2 border-ec-earth transition ease-in-out font-heading inline-flex items-center justify-center px-6 py-3 rounded-full text-xl text-white bg-ec-earth hover:bg-transparent hover:text-ec-earth focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ec-earth sm:w-auto"
                      >
                        <svg
                          className="fill-white animate-spin mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z" />
                        </svg>{" "}
                        Submitting...
                      </button>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <button
                        type="submit"
                        className="mt-2 w-full border-2 border-ec-earth transition ease-in-out font-heading inline-flex items-center justify-center px-6 py-3 rounded-full text-xl text-white bg-ec-earth hover:bg-transparent hover:text-ec-earth focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-ec-earth sm:w-auto"
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default Contact
